import React from "react"
import { formatDistanceToNow } from "date-fns"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import Chip from "@material-ui/core/Chip"
import Button from "@material-ui/core/Button"
// import Avatar from "@material-ui/core/Avatar"

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 12,
    marginTop: 12,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
    textAlign: "right",
  },
  pos: {
    marginBottom: 12,
    marginLeft: 5,
    marginRight: 5,
  },
})

export default function IoTCard({ isLoading, sensor, outside, loadData }) {
  const classes = useStyles()
  const spanner = symbol => <span className={classes.bullet}>{symbol}</span>

  const parseValue = val => (!val ? "..." : val)

  return (
    <Container maxWidth="sm">
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2">
            {parseValue(sensor && sensor.data.temperature)}
            {spanner("°C")}
            {spanner(" · ")}
            {parseValue(sensor && sensor.data.humidity)}
            {spanner("%")}

            <Typography
              className={classes.pos}
              color="textSecondary"
              component="span"
            >
              sensor{" "}
            </Typography>

            {sensor && sensor.ts && (
              <Chip
                disabled
                variant="outlined"
                size="small"
                label={`${formatDistanceToNow(new Date(sensor.ts * 1000))} ago`}
              />
            )}
          </Typography>

          <hr />

          <Typography variant="h5" component="h2">
            {parseValue(outside && outside.temp)}
            {spanner("°C")}
            {spanner(" · ")}
            {parseValue(outside && outside.humidity)}
            {spanner("%")}

            <Typography
              className={classes.pos}
              color="textSecondary"
              component="span"
            >
              outside{" "}
            </Typography>

            {outside && outside.dt && (
              <Chip
                disabled
                variant="outlined"
                size="small"
                label={`${formatDistanceToNow(
                  new Date(outside.dt * 1000)
                )} ago`}
              />
            )}
          </Typography>
        </CardContent>
        <CardActions>
          {/* <Button size="small" variant="outlined" onClick={loadData}>
            Refresh
          </Button>
          {!isLoading && (
            <Typography
              className={classes.title}
              color="textSecondary"
              title={formatISO(new Date(ts * 1000))}
            >
              {!isLoading && formatDistanceToNow(new Date(ts * 1000))} ago
            </Typography>
          )} */}

          {/* {!isLoading && sensor && (
            <Chip
            avatar={<Avatar>⟳</Avatar>}
            label={`${formatDistanceToNow(new Date(sensor.ts * 1000))} ago`}
            onClick={loadData}
            variant="outlined"
          />
          )}
           */}

          <Button
            size="small"
            disabled={isLoading}
            variant="outlined"
            onClick={loadData}
          >
            Refresh ⟳
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}
