import React from "react"
import { Helmet } from "react-helmet"
import IoTPlatform from "../../components/iot-platform/latest"

const IoTPlatformPage = () => (
  <>
    <Helmet>
      <title>IoT Platform</title>
      <meta name="robots" content="noindex" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
    </Helmet>
    <IoTPlatform />
  </>
)

export default IoTPlatformPage
