import React, { useState, useEffect } from "react"
import Card from "./Card"

function IoTPlatform() {
  const [dataPoint, setDataPoint] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const loadData = async () => {
    setIsLoading(true)

    // data --> { sensor, outside }
    const data = await fetch(
      `${process.env.GATSBY_API_URL}/iot-platform/latest`
    ).then(r => r.json())

    setDataPoint(data)
    setIsLoading(false)
  }

  useEffect(() => {
    loadData()

    const timerId = setInterval(loadData, 60 * 1000)
    return () => clearInterval(timerId)
  }, [])

  return <Card {...{ ...dataPoint, isLoading, loadData }} />
}

export default IoTPlatform
